<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <h1 class="mr-sm-4 header-tablepage">{{ pageDetail.name }}</h1>
      </div>

      <div class="title-tabs mt-3">{{ pageDetail.header }}</div>
      <div class="bg-white p-3">
        <b-row>
          <b-col cols="12" sm="8" xxl="6">
            <b-row class="align-items-center row-gap-lg">
              <b-col cols="2" class="text-center"> Sort Order</b-col>
              <b-col cols="4"> </b-col>

              <b-col cols="2"> </b-col>
              <b-col cols="2" class="text-center"> Default</b-col>
              <div class="break-normal"></div>
              <template v-for="(field, index) of list">
                <b-col cols="2" :key="'sort-order' + field.id + '-' + index">
                  <div class="content-between">
                    <font-awesome-icon
                      icon="chevron-circle-up"
                      class="icon-size"
                      @click="switchProfileIndex(field, index, false)"
                    />
                    {{ index + 1 }}
                    <font-awesome-icon
                      icon="chevron-circle-down"
                      :class="`icon-size`"
                      @click="switchProfileIndex(field, index, true)"
                    />
                  </div>
                </b-col>
                <b-col cols="4" :key="'name' + field.id + '-' + index">
                  <InputText
                    type="text"
                    placeholder=""
                    class="mb-0"
                    :v="$v.list.name"
                    :isValidate="$v.list.$each.$iter[index].name.$error"
                    v-model="field.name"
                /></b-col>

                <b-col cols="2" :key="'trash-' + field.id + '-' + index">
                  <font-awesome-icon
                    icon="trash-alt"
                    class="cursor-pointer"
                    @click="deleteField(index)"
                    v-if="list.length > 1"
                /></b-col>
                <b-col
                  cols="2"
                  :key="'check-box-' + field.id + '-' + index"
                  class="text-center"
                >
                  <b-form-checkbox
                    class="disableCheckbox"
                    :id="`checkbox-profile-${index}`"
                    :name="`checkbox-profile-${index}`"
                    v-model="field.is_default"
                    :value="1"
                    :unchecked-value="0"
                    @change="handleDefaultChange($event, index)"
                  >
                  </b-form-checkbox>
                </b-col>
                <div
                  class="break-normal"
                  :key="'break-' + field.id + '-' + index"
                ></div>
              </template>
              <b-col cols="2" class="text-center"> </b-col>
              <b-col cols="6" lg="5" xl="4" class="d-inline-flex">
                <b-button variant="custom-link mr-auto" @click="addField(1)"
                  >Add Choice</b-button
                >
              </b-col>
              <b-col cols="4"> </b-col>
              <b-col cols="2"> </b-col>
              <b-col cols="2"> </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>

      <FooterAction @submit="submit()" routePath="/setting" />
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";

export default {
  components: { OtherLoading },
  meta: { label: "custom-label" },
  validations() {
    return {
      list: {
        $each: {
          name: {
            required,
          },
        },
      },
    };
  },

  data() {
    return {
      list: [],
      listDelete: [],
      updateList: [],
      isLoading: true,
      pageDetail: {
        name: "Customer Type",
        header: "Customer Type",
        updated: "",
      },
      isDefault: null,
    };
  },
  async created() {},
  destroyed() {},
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.isLoading = true;
      const res = await this.axios(`setting/GetCustomerTypeSetting`);
      this.isDefault = res.data.detail.filter((el) => el.is_default == 1);
      this.list = res.data.detail.sort((a, b) => a.sort_order - b.sort_order);
      this.isLoading = false;
    },
    addField(type) {
      this.list.push({
        id: 0,
        name: `Customer Type ${this.list.length + 1}`,
        is_default: 0,
        sort_order: this.list.length + 1,
      });
    },
    deleteField(index) {
      const dele = this.list.splice(index, 1);
      this.listDelete.push(dele[0]);
    },
    async submit() {
      try {
        this.$v.list.$touch();
        if (this.$v.list.$error) return;
        if (this.list.every((el) => el.is_default == 0))
          return this.errorAlert("Please Select Default Type");
        this.$bus.$emit("showLoading");

        const res = await this.axios.put(`setting/UpdateCustomerTypeSetting`, {
          customer_type: this.list,
          delete: this.listDelete.filter((el) => el.id != 0).map((el) => el.id),
        });
        // this.getList();
        this.$bus.$emit("hideLoading");
        if (res.data.result == 1)
          this.successAlert().then(() => {
            this.$router.push("/setting");
          });
        else this.errorAlert();
      } catch (error) {
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
    async switchProfileIndex(items, index, val) {
      if (index == 0 && !val) {
        return;
      }

      this.list.splice(index, 1);
      index = val ? index + 1 : index - 1;

      this.list.splice(index, 0, items);
      var index = 0;
      for (const fields of this.list) {
        fields.sort_order = index;
        index++;
      }
      this.$nextTick(() => {
        this.list = [...this.list];
        // this.sortArray();
      });
    },
    handleDefaultChange(val, index) {
      this.$nextTick(
        () =>
          (this.list = [
            ...this.list.map((el, i) => {
              if (i == index) return el;
              return { ...el, is_default: 0 };
            }),
          ])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 20px;
  color: #575757;
  cursor: pointer;
}
</style>
